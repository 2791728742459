<template>
  <div class="technologylist">
    <div class="list" v-for="(item, index) of dataList" :key="index" @click="JumpDetails(item)">
      <p>{{ item.title || item.file_name }}</p>
      <div>
        <img v-if="!download" src="../../assets/iconImg/lt_yanjing.png" alt="">
        <span v-if="!download">{{ item.show_num }}</span>
        <img v-if="!download" src="../../assets/iconImg/xiazai.png" alt="">
        <span v-if="!download">{{ item.dw_num }}</span>


        <img v-if="download" src="../../assets/iconImg/lt_yanjing.png" alt="">
        <span v-if="download">{{ item.info == null ? '0' : item.info.show_num }}</span>
        <img v-if="download" src="../../assets/iconImg/xiazai.png" alt="">
        <span v-if="download">{{ item.info == null ? '0' : item.info.dw_num }}</span>


        <span class="right_span">{{ item.create_time }}</span>
        <img class="right_img" src="../../assets/iconImg/zhongbiao.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataList', 'download'],
  computed: {
    userinfo() {
      return this.$store.state.userinfo
    }

  },
  methods: {
    JumpDetails(item) {
      if (this.userinfo == null) {
        this.$message.error("您还没有登录，请先登录")
        this.$router.push("/login")
        return
      }
      if (item.class == 2) {
        this.postInformation(item)
      } else {
        this.initdetail(item)
      }

    },
    async initdetail(item) {
      let res = await this.$api.post("65096171076db", { aid: this.download == true ? item.xinxi_aid : item.aid })
      console.log(res)
      if (res.code == 1) {
        if (res.msg == "暂无数据") {
          this.$message.error("帖子已删除")
        } else {
          this.$router.push({
            path: '/postDetails',
            query: {
              t: 1,
              aid: this.download == true ? item.xinxi_aid : item.aid
            }
          })
        }
      }
    },
    async postInformation(item) {
      let res = await this.$api.post("6384686524dfb", { aid: item.xinxi_aid })
      if (res.code == 1) {
        if (res.msg == "暂无数据") {
          this.$message.error("帖子已删除")
        } else {
          this.$router.push({
            path: "/postDetails",
            query: {
              t: 4,
              aid: item.aid
            }
          })
          this.$store.commit("changeReplayUser", res.data)
        }


      }
    },
  }
}
</script>

<style lang="scss" scoped>
.technologylist {}

.list {
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  p {
    color: #333;
  }

  div {
    margin-top: 12px;

    img {
      width: 16px;
      height: 16px;
      vertical-align: -12%;
      margin-right: 6px;
    }

    span {
      color: #999;
      font-size: 14px;
      margin-right: 48px;
    }

    .right_span {
      float: right;
      margin-right: 0;
    }

    .right_img {
      float: right;
      margin-top: 2px;
    }
  }
}</style>