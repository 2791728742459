<template>
  <div class="empty">
        <img src="../assets/image/empty.png" alt="">
        <!-- <p>暂无数据</p> -->
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.empty{
    text-align: center;
    padding-bottom:20px;
    p{
        color:#333;
    }
    img{
        margin:60px auto 0 auto;
    }
}
</style>