<template>
  <div class="headerSearch">
    <div class="logo">
        <img src="../assets/image/logo.png" alt="">
    </div>
    <div class="nav">
        <div class="list" v-for="(item,index) of list" :key="index" @click="Jump(item,index)">
            <div :style="Index == index?'font-weight: bold;':''">{{ item.name }}</div>
            <div v-show="Index == index"></div>
        </div>
    </div>
    <div class="search" @mouseleave="handleMouseLeave">
       <div class="inputSty" @click="JumpSearch()">
            <input type="text" placeholder="搜一下"  @focus="historySearch = true" @input="historySearch = true"  v-model="keywords" @keydown.enter="changeSousuo">
            <img src="../assets/image/headerSearch.png" @click="changeSousuo" alt="">
       </div>
       <div class="historySearch_box" v-show="historySearch"   >
        <div class="top">
            <span class="name">历史搜索</span>
            <span class="delete" @click="histyAllDelete()">
                <img src="../assets/iconImg/history_delete.png" alt="">
                <span>全部删除</span>
            </span>
        </div>
        <div class="empty" v-if="historySearchData.length == 0">暂无历史搜索</div>
        <div class="list" v-for="(item,index) of historySearchData" :key="index">
            <div class="name" @click="keywords = item.name,changeSousuo()">{{ item.name }}</div>
            <div class="detele">
                <img @click="histyAllDelete(index)" src="../assets/iconImg/his_delete.png" alt="">
            </div>
        </div>
       </div>
    </div>
    <div class="login">
        <div class="fabu" @click="fabu()">  
            <span>
                <img src="../assets/image/fabu.png" alt="">
                发布
            </span>
        </div>
        <div class="denglu" v-if="userinfo == null" @click="login()">登录/注册</div>
        <div class="user" v-if="userinfo != null">
            <img  :src="userinfo.head_img" alt="" @click="JumpIndividual()">
            <span >{{ userinfo.user_nickname }}</span>
            <div class="information_box" >
                <div class="user_name">
                    <div v-if="userinfo.certified == 1" class="qiye">企业用户</div>
                    <div v-else>普通用户</div>
                   
                    <span class="edit" @click="JumpIndividual()">编辑资料</span>
                    <img  class="edit" @click="JumpIndividual()" src="../assets/iconImg/editziliao.png" alt="">
                </div>
                <div class="user_text" @click="changeAuthentication()" v-if="userinfo.certified != 1">
                    认证成为企业用户 >
                </div>
                <div class="user_text" v-if="userinfo.certified == 1" @click="changeAuthentication()" >
                   会员到期时间 {{ userinfo.user_vip_last_time }}  >
                </div>
                <!-- 重新认证延长有效期 > -->
                <!-- 组件 -->
                <div class="personaldata_box">
                    <personaldata type="ziliao"  ></personaldata>
                </div>
            </div>
        </div>
        
    </div>
    <!-- 发帖 -->
    <popupInvitation @clone="clone" @popupVis="popupInvitationFalse=false" v-if="popupInvitationFalse"></popupInvitation>
    <popupModule title="提醒"  width="420" height="238" v-if="popupModule" @clone = "popupModule= false" >
        <template slot="content">
                <div class="popupModule_content">
                    <div class="contentname">
                        取消发布，不保留任何信息，确定取消发布吗？
                    </div>
                    <div class="botbut">
                        <div @click="popupModule = false">再想想</div>
                        <div @click="popupInvitationFalse = false,popupModule = false">确定</div>
                    </div>
                </div>
        </template>
    </popupModule>
  </div>
</template>

<script>
import personaldata from "../views/components/personaldata.vue"
import popupInvitation from "@/components/popupInvitation.vue"
import moment from "moment"
export default {
    components:{
        personaldata,
        popupInvitation
    },
    data(){
        return{
            moment,
            list:[
                {
                    name:'首页',
                    url:"/"
                },
                {
                    name:'技术库',
                    url:"/technologystore"
                },
                {
                    name:'论坛',
                    url:"/forum"
                },
            ],
            popupInvitationFalse:false,
            popupModule:false,
            informationFalse:false,
            keywords:"",
            historySearch:false,
            historySearchData:localStorage.getItem("historySearchData")?JSON.parse(localStorage.getItem("historySearchData")):[],
            
        }
    },
    computed:{
        Index(){
            return this.$store.state.Index
        },
        userinfo(){
            return this.$store.state.userinfo
        }
    },
    mounted(){
        console.log("路由地址",this.$route.path)
        if(this.$route.path == '/technologystore'){
            this.$store.commit("changeIndex",1)
        }else if(this.$route.path == '/forum'){
            this.$store.commit("changeIndex",2)
        }
    },
    methods:{
        Jump(item,index){
            this.$store.commit("changeIndex",index)
            this.$router.push(item.url)
        },
        clone(){
            this.popupModule = true
        },
        login(){
            this.$router.push("/login")
        },
        JumpIndividual(){
            this.$router.push({
                path:"/user/Information",
                query:{
                    name:"个人信息"
                }
            })
        },
        changeAuthentication(){
            this.$router.push("/enterpriseAudit")
        },
        changeSousuo(){
            console.log(this.$route.path)
            this.$store.commit("changeKeywords",this.keywords)
            // if(this.$route.path != '/technologystore' && this.$route.path != '/forum'){
            //     this.$router.push("/forum")
            // }
            if(this.keywords != ''){
                this.changeHistorySearch()
            }

        },
        JumpSearch(){
            console.log(this.$route.path)
            if(this.$route.path != "/search"){
                this.$router.push("/search")
            }
        },
        changeHistorySearch(){
            let data = []
            for (var i = 0; i < this.historySearchData.length; i++) {
                if (this.historySearchData[i].name  !==  this.keywords) {
                    data.push(this.historySearchData[i])
                }
            }
            this.historySearchData = data
            if(this.historySearchData.length == 5){
                this.historySearchData.unshift({name:this.keywords})
                 this.historySearchData.pop();
                localStorage.setItem('historySearchData',JSON.stringify(this.historySearchData))
            }else{
                this.historySearchData.unshift({name:this.keywords})
                localStorage.setItem('historySearchData',JSON.stringify(this.historySearchData))
            }
        },
        histyAllDelete(index){
            if(index){  
                this.historySearchData.splice(index,1)
                localStorage.setItem('historySearchData',JSON.stringify(this.historySearchData))
            }else{
                this.historySearchData = []
                localStorage.removeItem('historySearchData')
            }
            
        },
        fabu(){
            if(this.userinfo == null){
                this.$router.push("/login")
            }else{
                this.popupInvitationFalse = true
            }
            
        },
         //移入移出
         handleMouseEnter(){
        },
        handleMouseLeave(){
            console.log("移出")
            this.historySearch = false
        },
    }
}
</script>

<style lang="scss" scoped>
.headerSearch{
    width:1200px;
    height:112px;
    margin:0 auto;
    &>div{
        float: left;
        height:112px;
    }
    .logo{
        width:70px;
        position: relative;
        img{
            width:112px;
            height:112px;
            position: absolute;
            top:0;
            left:-28px;
        }
    }
    .nav{
        display: flex;
        width:333px;
        margin-right:16px;
    }

}
.nav{
    .list{
        width:100px;
        text-align: center;
        cursor: pointer;
        
        &>div:nth-child(1){
            color:#fff;
            font-size:20px;
            height:30px;
            margin: 36px 0 8px 0;
        }
        &>div:nth-child(2){
            width:30px;
            height:3px;
            background:#fff;
            margin:0 auto;
        }
    }
}
.search{
    width:465px;
    height:112px;
    position: relative;
    .inputSty{
        width: 465px;
        height: 48px;
        border-radius: 24px 24px 24px 24px;
        opacity: 1;
        border: 1px solid #FFFFFF;
        display: flex;
        margin-top: 26px;
        input{
            background: transparent;
            border:0;
            height: 48px;
            padding-left:24px;
            color:#fff;
            font-size:16px;
            width: 370px;
        }
        input::placeholder {
            color:rgba(255,255,255,0.6);
        }
        input:focus{
            outline: none;
        }
        img{
            cursor: pointer;
        }
       img:active{
            opacity: 0.6;
       }
    }
    .historySearch_box{
        position: absolute;
        width: 564px;
        min-height:120px;
        border-radius: 16px;
        box-shadow: 0px 0px 20px 1px rgba(43,0,3,0.2);
        background:#fff;
        z-index: 9;
        top: 82px;
        left: -25px;
        .top{
            width:calc(100% - 40px);
            margin-left:20px;
            border-bottom:1px solid #eee;
            height:50px;
            line-height: 50px;
            .name{
                float: left;
                font-size:16px;
                color:#333;

            }
            .delete{
                float: right;
                cursor: pointer;
                img{
                    height:16px;
                    width:16px;
                    vertical-align: -5%;
                }
                span{
                    color:#999999;
                    font-size:14px;
                }
            }
        }
        .empty{
            color:#666666;
            font-size:16px;
            padding-left:20px;
            padding-top:20px;
        }
        .list{
            display: flex;
            height:50px;
            line-height: 50px;
            width:calc(100% - 40px);
            margin-left:20px;
            .name{
                color:#666;
                width:100%;
                overflow: hidden;
                cursor: pointer;
            }
            .detele{
                width:20px;
                cursor: pointer;
                img{
                    height:16px;
                    width:16px;
                    margin-top:2px;
                }
            }
        }
    }
}
.login{
    display: flex;
    height:112px;
    width:316px;
    &>div{
        width:116px;
        height:48px;
        border-radius: 24px 24px 24px 24px;
        font-size:16px;
        border: 1px solid #FFFFFF;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        margin-top: 24px;
    }
    &>div:active{
        opacity: 0.6;
    }
    .fabu{
        color:#fff;
        margin-right:24px;
        margin-left:56px;
        img{
            vertical-align: -8%;
        }
    }
    .denglu{
        color:#9C090F;
        background:#fff;
    }
    .user{
        width:108px;
        height:80px;
        border:0;
        position: relative;
        // margin-left:36px;
        &:active{
            opacity: 1;
        }
        img{
            width:28px;
            height:28px;
            border-radius: 50%;
            margin:0 auto;
        }
        span{
            font-size:12px;
            color:#fff;
            display: block;
            line-height: 0;
            width:108px;
        }
        &:hover>.information_box{
            display: block;
        }
        .information_box{
            width: 380px;
            height: 720px;
            background: #FFFFFF;
            box-shadow: 0px 0px 20px 1px rgba(43,0,3,0.2);
            border-radius: 16px 16px 16px 16px;
            position: absolute;
            bottom:-696px;
            right:0;
            z-index: 9;
            line-height:normal;
            text-align:none;
            cursor: default;
            display: none;
           .user_name{
                padding:0 20px;
                overflow: hidden;
                margin-top:26px;
                div{
                    float: left;
                    width: 84px;
                    height: 32px;
                    color: #EF5959;
                    border-radius: 22px 22px 22px 22px;
                    text-align: center;
                    background: rgba(239, 89, 89, 0.4);
                    line-height: 32px;
                    font-size:14px;
                }
                div.qiye{
                    color:rgba(89, 90, 212, 1);
                    background:rgba(89, 90, 212, 0.4)
                }
                img{
                    float: right;
                    width:20px;
                    height:20px;
                    margin-top:6px;
                    margin-right:4px;

                }
                span{
                    float: right;
                    color:#333;
                    font-size:14px;
                    display: block;
                    height:32px;
                    line-height: 32px;
                }
                .edit:active{
                    opacity: 0.6;
                    cursor: pointer;
                }
           }
           .user_text{
                color:rgba(102, 102, 102, 1);
                font-size:14px;
                height:20px;
                width:100%;
                text-align: left;
                line-height: 20px;
                padding-left:20px;
                margin-top:12px;
                cursor: pointer;
                &:active{
                    opacity: 0.6;
                }
           }
           .personaldata_box{
                padding:0 20px;
                border-top:1px solid #eee;
                margin-top:20px;
           }
        }
    }
}
.popupModule_content{
    width:100%;
    .contentname{
        color:#333;
        height:93px;
        border-bottom:1px solid #eee;
        line-height:80px;
        text-align: center;
    }
    .botbut{
        height:80px;
        div{
            height:40px;
            width:160px;
            margin-top:20px;
            border:1px solid #9A080D;
            border-radius: 28px;
            cursor: pointer;
            text-align: center;
            line-height: 40px;
            &:active{
                opacity: 0.6;
            }
        }
        &>div:nth-child(1){
            float: left;
            margin-left:40px;
            color:#9A080D;
            background:rgba(154, 8, 13, 0.06);

        }
        &>div:nth-child(2){
            float: right;
            margin-right:40px;
            color:#fff;
            background:#9A080D;
        }
    }
}
</style>