<template>
    <div class="home">
      <!-- <v-scale-screen  width="1920" height="1080" > -->
        <div class="box">
          <div class="loginTop">
            <loginTop></loginTop>
          </div>
          <div class="header" >
            <headerSearch></headerSearch>
          </div>
          <div class="content_app_box scroll">
            <router-view/>
          </div>
        </div>
  
        
  
  
      <!-- </v-scale-screen> -->
      
    </div>
  </template>
  <script>
  import VScaleScreen from 'v-scale-screen'
  import loginTop from '@/components/loginTop.vue'
  import headerSearch from '@/components/headerSearch.vue'
  export default{
    components:{
      VScaleScreen,
      loginTop,
      headerSearch
    },
    data(){
      return{
        routeName:""
      }
    },
    watch:{
      $route(to,from){
        console.log(to)
        this.routeName = to.name
      }
    },
    computed:{
      userinfo() {
            return this.$store.state.userinfo
        }
    },
    mounted(){
      this.reportClass()
      this.RenewUser()
    },
    methods:{
       // 举报类型
       async reportClass(){
            let res = await this.$api.post('65264482b7f58 ')
            this.$store.commit("changeReport",res.data)
        },
      //获取更新会员详细信息
      async RenewUser(){
        if(!this.userinfo) return
            let res = await this.$api.post('5c78c4772da97',{user_id:this.userinfo.id})
            let user = {
              ...this.userinfo,
              ...res.data,
              

            }
            this.$store.commit("userInfo",user)
        },
    }
  }
  </script>
  <style lang="scss" scoped>
  .home {
    font-family: "微软雅黑";
    font-size: 16px;
    overflow: hidden;
    &>div{
      background: none !important;
    }
    .box{
  
    }
    .header{
      // width:1920px;
      height:112px;
  
      background:#9C090F url("../assets/image/headerImg.png") no-repeat  center;
    }
  }
  .content_app_box{
    padding-bottom:40px;
  }
  
  
  
  </style>
  