<template>
  <div class="popupInvitation">
    <div class="box ">
      <div class="title">
        <div class="menu">
          <div :class="menuFalse ? 'sty' : ''" @click="menuFalse = true">发帖子</div>
          <div :class="!menuFalse ? 'sty' : ''" @click="menuFalse = false">提问题</div>
        </div>
        <i class="icon iconfont icon-close" @click="clone"></i>
      </div>

      <div class="content scrollShow" v-if="menuFalse">
        <div class="title_box" style="height:34px;">
          <input class="title" type="text" maxlength="30" v-model="postTitle" placeholder="填写标题会有更多赞哦-">
          <span class="maxtext">({{ postTitle.length }}/30)</span>
        </div>

        <div class="imgup_box">
          <p>上传封面图（选填）</p>
          <div class="fengmian_box">
            <img v-if="imageUrl.length == 1" :src="imageUrl[0]" alt="">
            <i v-show="imageUrl.length == 1" class="icon iconfont icon-close" @click="imageUrl = []"></i>
            <el-upload v-show="imageUrl.length == 0" :action="serverConfig.baseURL + '62ddf0e4eadde'"
              list-type="picture-card" class="uploadSty" :show-file-list="false" :on-success="handleAvatarSuccess"
              :on-progress="changeProgress" :before-upload="beforeAvatarUpload">

              <div v-show="imageUrl.length == 0" class="upload_img_box">
                <span>上传封面</span>
              </div>
            </el-upload>
          </div>


        </div>

        <Tinymce class="TinSty" v-model="TinymceName"></Tinymce>
        <div class="tixing">注:页面宽度为800,请根据页面调整图片大小</div>
        <el-radio class="radioSty" v-model="down_status" label="1">可下载</el-radio>
        <el-radio class="radioSty" v-model="down_status" label="0">不可下载</el-radio>
        <el-upload class="upload-demo" :action="serverConfig.baseURL + '5fc615f7da92b'" :on-success="ossSuccess"
          :on-remove="ossRemove" :on-progress="changeProgress" :before-upload="onbeforeAvatarUpload"
          :file-list="fileList">
          <div class="upBut"> + 上传文件</div>

          <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>

        <div class="publish">
          <div @click="publishPost()">发布</div>
        </div>
      </div>

      <div class="content scrollShow" v-if="!menuFalse">
        <div class="title_box title_box_2">
          <input class="title" type="text" maxlength="30" v-model="ReplayTitle" placeholder="填写标题会有更多赞哦-">
          <span class="maxtext">({{ ReplayTitle.length }}/30)</span>
        </div>
        <div class="title_box title_box_2 title_box_3">
          <input class="title" type="text" v-model="ReplayContent" placeholder="对问题补充说明，可以更快获得解答">
        </div>

        <div class="imgup_box">
          <div class="upimgBox">

            <div v-for="(item, index) of imageUrl" :key="index">
              <i class="icon iconfont icon-close" @click="imgDelit(item, index)"></i>
              <img :src="item" alt="">
            </div>
            <div v-show="videoUrl != ''">
              <i class="icon iconfont icon-close" @click="videoUrl = ''"></i>
              <videoPlayer v-if="videoUrl != ''" :src="videoUrl"></videoPlayer>
            </div>
          </div>
          <div class="uploadSty_box">
            <el-upload v-if="imageUrl.length < 9 && videoUrl == ''" :action="serverConfig.baseURL + '62ddf0e4eadde'"
              list-type="picture-card" class="uploadSty" :on-progress="changeProgress" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
              <div class="upload_img_box">
                <span>上传图片</span>
              </div>
            </el-upload>

            <el-upload v-show="imageUrl.length == 0 && videoUrl == ''" :action="serverConfig.baseURL + '5fc615f7da92b'"
              :show-file-list="false" class="uploadSty" :on-progress="changeProgress"
              :before-upload="changebeforeAvatarUpload" :on-success="upVideo">
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
              <div class="upload_shipin_box">
                <span>上传视频</span>
              </div>
            </el-upload>

            <el-upload v-if="videoUrl != '' && imageUrl.length == 0" :action="serverConfig.baseURL + '62ddf0e4eadde'"
              list-type="picture-card" class="uploadSty" :on-progress="changeProgress" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
              <div class="upload_img_box">
                <span>上传封面</span>
              </div>
            </el-upload>
          </div>

        </div>

        <div class="publish">
          <div @click="publishReplay()">发布</div>
        </div>
      </div>



    </div>
  </div>
</template>
  
<script>
import Tinymce from "./tinymce.vue"
import serverConfig from "../http/config.js"
import videoPlayer from "./videoPlayer.vue"
export default {
  props: ["title"],
  components: {
    Tinymce,
    videoPlayer
  },
  data() {
    return {
      serverConfig,
      menuFalse: true,
      TinymceName: "",
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      imageUrl: [],
      ReplayTitle: "",
      ReplayContent: "",
      dost: true,
      postTitle: "",
      videoUrl: "",
      loading: null,
      down_status: '1'
    }
  },
  watch: {
    menuFalse() {
      this.postTitle = ""
      this.ReplayTitle = ""
      this.ReplayContent = ""
      this.TinymceName = ""
      this.imageUrl = []
      this.videoUrl = ""
    }
  },
  methods: {
    clone() {
      this.$emit("clone")
    },
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
      if (this.menuFalse) {
        this.imageUrl = []
        this.imageUrl = [res.data[0].path]
      } else {
        this.imageUrl.push(res.data[0].path)
      }
      console.log("上传图片", this.imageUrl)
      this.loading.close();

    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isImage && isLt2M;
    },

    imgDelit(item, index) {
      this.imageUrl.splice(index, 1)
    },
    ossSuccess(res, file, fileList) {
      console.log(res)
      console.log("文件", fileList)
      this.fileList = fileList
      this.loading.close();
    },
    ossRemove(file, fileList) {
      console.log(fileList)
      this.fileList = fileList
    },
    onbeforeAvatarUpload(file) {
      console.log("上传的文件", file)
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 pdf、ppt、doc、docx、xls、xlsx格式", "error");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 500MB!');
        return false;
      }


    },
    changeProgress() {
      console.log("上传中")

      this.loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.6)'
      });
    },
    changebeforeAvatarUpload(file) {
      let types = ['video/mp4'];
      const isImage = types.includes(file.type);
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error('请上传MP4格式的视频!');
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isImage;
    },
    upVideo(res, file, fileList) {
      console.log("上传了视频", res, file)

      if (file.raw.type == "video/mp4") {
        this.videoUrl = ""
        this.videoUrl = res.data.data.file
      } else {
        this.$message.error("请上传MP4格式的视频")
      }
      this.loading.close();
    },
    // 回答问题
    async publishReplay() {
      if (this.ReplayTitle == "") {
        this.$message.error("请填写标题");
        return
      }
      if (this.ReplayContent == "") {
        this.$message.error("请填写内容");
        return
      }
      let images = JSON.stringify(this.imageUrl)
      let data = {
        title: this.ReplayTitle,
        content: this.ReplayContent,
        images: images,
        video: this.videoUrl
      }
      if (this.dost) {
        this.dost = false
        let res = await this.$api.post("651f8028a0ed3", data)
        if (res.code == 1) {
          this.$message.success("发布成功")
          this.ReplayVisible = false
          let _this = this
          setTimeout(() => {
            if (_this.$route.path == "/forum") {
              location.reload()
            } else {
              _this.$router.push("/forum")
              this.$emit("popupVis")
            }
            _this.dost = true
          }, 400)
        } else {
          this.dost = true
        }
      }

    },
    async publishPost() {
      if (this.postTitle == "") {
        this.$message.error("请填写标题");
        return
      }
      if (this.TinymceName == "") {
        this.$message.error("请发表帖子内容");
        return
      }

      let data = {
        title: this.postTitle,
        resources: this.imageUrl.length == 1 ? this.imageUrl[0] : "",
        content: this.TinymceName,
        file_list: this.fileJson(this.fileList)
      }
      console.log("参数", data)
      if (this.dost) {
        this.dost = false
        let res = await this.$api.post("638470f051363", data)
        if (res.code == 1) {
          this.$message.success("发布成功")
          this.ReplayVisible = false
          let _this = this
          setTimeout(() => {
            if (_this.$route.path == "/forum") {
              location.reload()
            } else {
              _this.$router.push("/forum")
              this.$emit("popupVis")
            }

            _this.dost = true
          }, 400)
        } else {
          this.dost = true
        }
      }
    },
    fileJson(data) {
      let arr = []
      data.forEach(r => {
        let index = r.name.indexOf(".");
        let item = {
          file_name: r.name.substr(0, index),
          type: r.name.substring(r.name.lastIndexOf(".") + 1),
          file: r.response.data.data.file,
          down_status: this.down_status
        }
        arr.push(item)
      });
      console.log("文件上传", arr)
      return JSON.stringify(arr)
    }
  }
}
</script>
  
<style lang="scss" scoped>
.popupInvitation {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  position: fixed;
  border-radius: 0 !important;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;

  &:active {
    opacity: 1;
  }
}

.box {
  width: 968px;
  max-height: 840px;
  border-radius: 16px;
  background: #fff;
  margin: 30px auto 0 auto;
  overflow: hidden;


  .title {
    height: 64px;
    line-height: 64px;
    text-align: center;
    position: relative;

    .menu {
      margin: 40px auto 0 auto;
      width: 204px;
      height: 48px;
      background: #F4F4F4;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      display: flex;
      cursor: pointer;

      div {
        width: 102px;
        text-align: center;
        line-height: 48px;
        color: #333;
      }

      .sty {
        background: #9C090F;
        color: #fff;
        border-radius: 24px;
      }
    }

    .icon {
      margin-right: 36px;
      cursor: pointer;
      color: #999;
      font-size: 20px;
      position: absolute;
      right: 0;
      top: -16px;
    }
  }

  .content {
    padding: 20px 40px;
    max-height: 700px;
    width: calc(100% - 80px);
    overflow: scroll;

    .title_box {
      height: 60px;
      border-bottom: 1px solid #eee;
      font-size: 14px;

      .title {
        border: 0;
        height: 20px;
        color: #333;
        width: 370px;
        text-align: left;
        font-weight: bold;
      }

      .maxtext {
        color: #999;
      }

      input::placeholder {
        color: #999;
      }
    }

    .title_box_2 {
      height: 30px;
      border-bottom: 0;
    }

    .title_box_3 {
      border-bottom: 1px solid #eee;

      .title {
        font-weight: normal;
      }
    }

    .imgup_box {
      margin-top: 20px;
      overflow: hidden;

      p {
        color: #333;
        margin-top: 32px;
        margin-bottom: 10px;
      }
    }

    .publish {
      height: 80px;
      border-top: 1px solid #eee;
      width: 100%;
      margin-top: 20px;

      div {
        text-align: center;
        height: 40px;
        color: #fff;
        width: 280px;
        background: #9C090F;
        border-radius: 44px;
        margin: 20px auto;
        line-height: 40px;
        cursor: pointer;

        &:active {
          opacity: 0.6;
        }
      }
    }
  }
}

.TinSty {
  margin-top: 32px;
}

.upBut {
  width: 124px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 32px 32px 32px 32px;
  opacity: 1;
  border: 1px solid #595AD4;
  text-align: center;
  line-height: 36px;
  color: #595AD4;
  margin-top: 20px;

  &:active {
    opacity: 0.6;
  }
}

.upimgBox {
  overflow: hidden;
  // width: 100%;
  float: left;
  padding-top: 10px;

  div {
    width: 80px;
    height: 80px;
    position: relative;
    float: left;
    margin: 0 8px 8px 0;
    // overflow: hidden;

    .icon {
      cursor: pointer;
      position: absolute;
      right: -5px;
      top: -5px;
      color: #999;
      display: none;
      background: #eee;
      border-radius: 50%;
      font-size: 10px;
      padding: 4px;
      z-index: 9;

      &:active {
        opacity: 0.6;
      }
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
    }

    &:hover .icon {
      display: block;
    }

    video {
      width: 80px;
      height: 80px;
    }
  }
}

.fengmian_box {
  width: 80px;
  height: 80px;
  display: flex;

  &>img {
    margin-right: 8px;
    border-radius: 6px;
  }
}

.uploadSty {
  width: 80px;
  margin-right: 16px;
}

.uploadSty_box {
  display: flex;
  float: left;
  padding-top: 10px;
}

.fengmian_box {
  position: relative;

  &:hover .icon {
    display: block;
  }

  .icon {
    cursor: pointer;
    position: absolute;
    right: -5px;
    top: -5px;
    color: #999;
    display: none;
    background: #eee;
    border-radius: 50%;
    font-size: 10px;
    padding: 4px;

    &:active {
      opacity: 0.6;
    }
  }
}

.radioSty {
  margin-top: 20px;
}

.tixing {
  margin-top: 4px;
  color: red;
  font-size: 12px;
}
</style>