<template>
  <div class="index">
    <!-- baner -->
    <div class="bannerbox">
      <swiper :options="swiperOption" ref="mySwiper" id="mySwiper">

        <!--必须的组件-->
        <!--每页幻灯片使用swiper-slide标签-->
        <!--幻灯片组件生成的标签自带.swiper-slide的类名，但单类名选择器设置的部分css(如宽高)将被覆盖-->
        <swiper-slide class="swiper_slide_item" v-for="(item, index) of advertisingData" :key="index">
          <!-- <img :src="item.thumb" :data-link_type="item.link_type" :data-href="item.href"> -->
          <!-- <img :src="item.thumb" :alt="item.name" @click="JumpCarousel(item)"> -->
		  <img :src="item.thumb" :alt="item.name" >
        </swiper-slide>

        <!-- 可选的控件 -->
        <!--分页器-->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <div class="content">
      <div class="box">
        <!-- 技术推荐 -->
        <div class="technology_box">
          <technology type="max"></technology>
        </div>
        <!-- 平台公告 -->
        <div class="notice_box">
          <notice></notice>
        </div>
        <!-- 个人资料 -->
        <div class="personaldata_box">
          <personaldata></personaldata>
        </div>
        <!-- 论坛 -->

        <div class="forum_box">
          <forum type="home" sort_field="create_time"></forum>
        </div>
      </div>

      <!-- 认证弹窗 -->
      <!-- <popupPermissions></popupPermissions> -->
      <indexBottom></indexBottom>
    </div>
  </div>
</template>

<script>
import indexBottom from "@/components/indexBottom.vue"
import notice from "./components/notice.vue"
import technology from "./components/technology.vue"
import personaldata from "./components/personaldata.vue"
import forum from "./components/forum.vue"
import { swiperOption } from "./swiper.js"
export default {
  components: {
    indexBottom,
    technology,
    notice,
    personaldata,
    forum
  },
  data() {
    return {
      swiperOption,
      advertisingData: [{}, {}, {}]
    }
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo
    },

  },
  mounted() {
    if (typeof localStorage.getItem("userinfo") == "string") {
      let userinfo = localStorage.getItem("userinfo")
      this.$store.commit("userInfo", JSON.parse(userinfo))
    }
    this.Carousel()
  },
  methods: {
    async Carousel() {
      let res = await this.$api.post("62c7e3aba33c4", { type_id: 1 })
      if (res.code == 1) {
        this.advertisingData = res.data
      }
    },
    JumpCarousel(item) {
      // this.$router.push(item.href)
      let routeUrl = this.$router.resolve({
              path: item.href,
          });

      window.open(routeUrl.href, '_blank');
    },
    //获取更新会员详细信息
    async RenewUser() {
      if (!this.userinfo) return
      let res = await this.$api.post('5c78c4772da97', { user_id: this.userinfo.id })
      let user = {
        ...this.userinfo,
        ...res.data,


      }
      this.$store.commit("userInfo", user)
    },

  }

}
</script>

<style lang="scss" scoped>
.bannerbox {
  width: 1200px;
  height: 280px;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;

  #mySwiper {
    width: 1200px;
    height: 280px;

    img {
      width: 1200px;
      height: 280px;
    }
  }
}

.content {}

.box {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto 20px auto;
}

.technology_box {
  width: 840px;
  height: 349px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid #EEEEEE;
  float: left;
  overflow: hidden;
}

.notice_box {
  float: right;
  width: 340px;
  height: 295px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid #EEEEEE;
}

.personaldata_box {
  width: 340px;
  height: 540px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid #EEEEEE;
  float: right;
  margin-top: 20px;
}

.forum_box {
  width: 840px;
  height: 376px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 1px solid #EEEEEE;
  float: left;
  margin-top: 20px;
  padding-bottom: 110px;
}</style>