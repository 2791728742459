<template>
  <div class="popupModule">
    <div class="box" :style="`width:${width}px;height:${height}px;`">
        <div class="title" v-if="title">
            <span class="text">{{ title }}</span>
            <i class="icon iconfont icon-close" @click="clone"></i>
        </div>

        <div class="content scrollShow">
            <!-- template v-slot:content -->
            <slot name="content">
                <empty></empty>
            </slot>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:["title","width",'height'],
    methods:{
        clone(){
            this.$emit("clone")
        }
    }
}
</script>

<style lang="scss" scoped>
.popupModule{
    width:100%;
    height:100% !important;
    position: fixed;
    top:0;
    left:0;
    background: rgba(0,0,0,0.2);
    z-index: 999;
}
.box{
    width:800px;
    // height:750px;
    border-radius: 16px;
    background:#fff;
    margin:100px auto 0 auto;
    overflow: hidden;
    .title{
        height:64px;
        background:#f9f9f9;
        line-height:64px;
        text-align: center;
        .text{
            margin-left:40px;
            font-size:20px; 
            font-weight: bold;
        }
        .icon{
            float: right;
            margin-right:36px;
            cursor: pointer;
            color:#999;
            font-size:20px;
        }
    }
    .content{
        // height:calc(100% - 64px);
        // overflow: scroll;
        // padding:20px 40px;
        // width:calc(100% - 80px);
    }
}
</style>