<template>
    <div class="popupPermissions">
      <div class="box" :style="`width:${width}px;height:${height}px;`">
            <h2>当前暂无权限</h2>
            <p>认证成为企业用户可享受更多权益</p>
            <div class="cl1">认证企业用户</div>
            <div class="cl2">我知道了</div>
      </div>
      <div class="box" :style="`width:${width}px;height:${height}px;`">
            <h2>企业用户权限已过期</h2>
            <p>当前暂无权限</p>
            <p style="margin-top:4px;">重新认证可享受更多权益</p>
            <div class="cl1">重新认证</div>
            <div class="cl2">我知道了</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props:["title","width",'height'],
      methods:{
          clone(){
              this.$emit("clone")
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .popupPermissions{
      width:100%;
      height:100% !important;
      position: fixed;
      top:0;
      left:0;
      background: rgba(0,0,0,0.6);
      z-index: 999;
  }
  .box{
      width:420px;
      padding-bottom:40px;
      border-radius: 16px;
      background:#fff;
      margin:100px auto 0 auto;
      overflow: hidden;
      text-align: center;
      h2{
        color:#333;
        margin-top:40px;
      }
     p{
        color:#999;
        margin-top:16px;
     }
     .cl1{
        width: 240px;
        height: 40px;
        background: #9A080D;
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
        color:#fff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        border:1px solid #9A080D;
        margin:32px auto 0 auto;
        &:active{
            opacity: 0.6;
        }
     }
     .cl2{
        width: 240px;
        height: 40px;
        background: rgba(154, 8, 13, 0.06);
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
        color:#9A080D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        border:1px solid #9A080D;
        margin:16px auto 0 auto;
        &:active{
            opacity: 0.6;
        }
     }
  }
  </style>