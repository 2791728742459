import { render, staticRenderFns } from "./indexBottom.vue?vue&type=template&id=356dc1a9&scoped=true"
import script from "./indexBottom.vue?vue&type=script&lang=js"
export * from "./indexBottom.vue?vue&type=script&lang=js"
import style0 from "./indexBottom.vue?vue&type=style&index=0&id=356dc1a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356dc1a9",
  null
  
)

export default component.exports