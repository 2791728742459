<template>
  <div id="app">
    <!-- <v-scale-screen  width="1920" height="1080" > -->
      <!-- <div class="box">
        <div class="loginTop">
          <loginTop></loginTop>
        </div>
        <div class="header" >
          <headerSearch></headerSearch>
        </div>
        <div class="content_app_box scroll">
          <router-view/>
        </div>
      </div> -->
      <router-view/>
      


    <!-- </v-scale-screen> -->
    
  </div>
</template>
<script>
import VScaleScreen from 'v-scale-screen'
import loginTop from '@/components/loginTop.vue'
import headerSearch from '@/components/headerSearch.vue'
export default{
  components:{
    VScaleScreen,
    loginTop,
    headerSearch
  },
  data(){
    return{
    }
  },
  watch:{
  }
}
</script>
<style lang="scss">
@import  "@/style/swiper.scss";
@import  "@/style/public.scss";
#app {
  font-family: "微软雅黑";
  font-size: 16px;
  overflow: hidden;
}
* {
  padding: 0;
  margin: 0;
}



</style>
