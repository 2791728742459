<template>
  <div class="personaldata">
    <div class="top">
        <div class="top_1 borbot">
            <div @click="JumpUser()">
                <img src="../../assets/iconImg/shouchang.png" alt="">
                <div>
                    <span>{{ fanslist.shoucang||0 }}</span>
                    <p>收藏</p>
                </div>
            </div>
            <div @click="JumpUser()">
                <img src="../../assets/iconImg/guanzhu.png" alt="">
                <div>
                    <span>{{ fanslist.guanzhu||0 }}</span>
                    <p>关注</p>
                </div>
            </div>
        </div>
        <div class="top_1 top_2">
            <div @click="JumpUser()">
                <img src="../../assets/iconImg/fensi.png" alt="">
                <div>
                    <span>{{ fanslist.fensi||0 }}</span>
                    <p>粉丝</p>
                </div>
            </div>
            <div @click="JumpUser()">
                <img src="../../assets/iconImg/fabu.png" alt="">
                <div>
                    <span>{{ fanslist.fabu||0 }}</span>
                    <p>发布</p>
                </div>
            </div>
        </div>
    </div>

    <div class="list_box">
        <div class="list" @click="JumpRouter('/Integral/Integralmall','积分商城')">
            <img class="left" src="../../assets/iconImg/jifenshangcheng.png" alt="">
            <span class="name left">积分商城</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
            <span class="myjifen right" v-if="userinfo">
                我的积分: {{ userinfo.score}}
            </span>
        </div>
        <div class="list" @click="JumpRouter('/setup/agreementEssay?id=6','平台介绍')">
            <img class="left" src="../../assets/iconImg/pingtaijieshao.png" alt="">
            <span class="name left">平台介绍</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
        </div>
        <div class="list" @click="JumpRouter('/other/downloadRecording','资料下载记录')">
            <img class="left" src="../../assets/iconImg/zhiliaoxiazai.png" alt="">
            <span class="name left">资料下载记录</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
        </div>
        <div class="list" @click="JumpRouter('/other/addressAdmin','地址管理')">
            <img class="left" src="../../assets/iconImg/dizhiguanli.png" alt="">
            <span class="name left">地址管理</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
        </div>
        <div class="list"  @click="JumpRouter('/setup/accountSafe','账号与安全')">
            <img class="left" src="../../assets/iconImg/shezhi.png" alt="">
            <span class="name left">设置</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
        </div>
        <div class="list" v-if="type == 'ziliao'" @click="quitLogin()">
            <img class="left" src="../../assets/iconImg/tuichudenglu.png" alt="">
            <span class="name left">退出登录</span>
            <span class="icon iconfont icon-xiangyou1 right"></span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['type'],
    computed: {
        userinfo() {
            return this.$store.state.userinfo
        }
    },
    data(){
        return{
            fanslist:{

            },
        }
    },
    watch:{
        $route(){
            if(this.userinfo){
                this.changeFans()
            }
        }
    },
    mounted(){
        if(this.userinfo){
            this.changeFans()
        }
        
        // this.changehelp()
    },
    methods:{
        quitLogin(){
            this.$store.commit("userInfo",null)
            this.$router.push("/login")
        },
        JumpRouter(url,name){
            if(this.userinfo == null){
                this.$message.error("您还没有登录，请先登录")
                this.$router.push("/login")
            }else{
                this.$router.push({
                    path:url,
                    query:{
                        name:name
                    }
                })
            }
           
        },
        JumpUser(){
            if(this.userinfo == null){
                this.$message.error("您还没有登录，请先登录")
                this.$router.push("/login")
            }else{
                this.$router.push({
                    path:'/user/home',
                    query:{
                        name:'我的主页'
                    }
                })
            }
            
        },
         //我的粉丝 关注 收藏
         async changeFans() {
            let res = await this.$api.post('652de2109899c',{user_id:this.userinfo.id})
            if (res.code == 1) {
                this.fanslist = res.data

            }
        },
    }
}
</script>

<style lang="scss" scoped>
.top{
    padding:40px 20px;
    border-bottom:1px solid #eee;
    width:300px;
    .borbot{
        border-bottom:1px solid #eee;
    }
    .top_1{
        width:300px;
        
        height:96px;
        display: flex;
        &>div{
            display: flex;
            width:150px;
            height:96px;
            cursor: pointer;
            img{
                width:56px;
                height:56px;
            }
            &>div{
                margin-left:10px;
                span{
                    font-size: 24px;
                    font-weight: bold;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
        &>div:nth-child(1){
            box-sizing: border-box;
            border-right:1px solid #eee;
            margin-left:16px;
        }
        &>div:nth-child(2){
            margin-left:37px;
        }
    }
    .top_2{
        &>div{
            padding-top:40px;
        }
    }
}
.list_box{
    padding:0 20px;
    .list{
        overflow: hidden;
        cursor: pointer;
        height:24px;
        line-height: 24px;
        margin-top:26px;
        &:hover{
            opacity: 0.6;
        }
        .left{
            float: left;
        }
        .right{
            float: right;
        }
        img{
            width:24px;
            height:24px;
            margin-right:8px;
        }
        .name{
            color:#333;
        }
        .icon{
            color:#999;
        }
        .myjifen{
            color:#666;
            font-size:14px;
            margin-right:8px;
        }
    }
}
</style>