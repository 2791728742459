<template>
    <div :class="type == 'max' ? 'technology_max' : 'technology_min'">
        <div class="top">
            <img src="../../assets/image/technology/huo.png" alt="">
            <h2>技术资料推荐</h2>
            <div @click="JumpTech()">
                更多
                <span class="icon iconfont icon-xiangyou1"></span>
            </div>
        </div>
        <div class="list_box">
            <div class="list" v-for="(item, index) of dataList" :key="index" @click="Jumpxq(item)">
                <div class="dian"></div>
                <div class="text omit">{{ item.title }}</div>
                <div class="time">{{ item.create_time }}</div>
            </div>
            <empty v-if="dataList.length == 0 && !loading"></empty>
            <div class="icon_loading" v-show="loading">
                <i class="icon iocnfont el-icon-loading"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type"],
    data() {
        return {
            dataList: [],
            loading: false
        }
    },
    computed: {
        userinfo() {
            return this.$store.state.userinfo
        }

    },
    mounted() {
        this.init()
    },
    methods: {
        JumpTech() {
            this.$router.push("/technologystore")
        },
        async init() {
            this.loading = true
            let res = await this.$api.post("652a05887c5b6")
            console.log(123, res)
            if (res.code == 1) {
                this.dataList = res.data.data
                console.log(this.dataList)
                this.loading = false
            } else {
                this.loading = false
            }
        },
        Jumpxq(item) {
            if (this.userinfo == null) {
                this.$message.error("您还没有登录，请先登录")
                this.$router.push("/login")
                return
            }
            console.log(this.$route.path)

            // if (this.$route.path == "/postDetails") {
            //     this.$router.push({
            //         path: '/postDetails',
            //         query: {
            //             t: 1,
            //             aid: item.aid,
            //         }
            //     })

            //     location.reload()
            // } else {
            //     this.$router.push({
            //         path: '/postDetails',
            //         query: {
            //             t: 1,
            //             aid: item.aid,
            //         }
            //     })
                

            // }
            this.$store.commit("changeIndex", 1)
                let routeUrl = this.$router.resolve({
                    path: "/postDetails",
                    query: {
                        t: 1,
                        aid: item.aid,
                    }
                });

                window.open(routeUrl.href, '_blank');

        },

    }

}
</script>

<style lang="scss" scoped>
.technology_max {
    .top {
        height: 30px;
        padding-top: 20px;
        margin-bottom: 24px;

        img {
            float: left;
            margin-left: 20px;
            margin-right: 12px;
        }

        h2 {
            float: left;
        }

        div {
            float: right;
            color: #9C090F;
            font-size: 16px;
            cursor: pointer;
            line-height: 30px;
            margin-right: 20px;
        }

        div:active {
            opacity: 0.6;
        }
    }

    .list_box {
        overflow: hidden;

        .list {
            display: flex;
            width: 840px;
            height: 30px;
            line-height: 30px;
            margin: 16px 0;

            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }

            .dian {
                width: 8px;
                height: 8px;
                background: #EF5959;
                border-radius: 50%;
                margin-top: 11px;
                margin-left: 20px;
                margin-right: 12px;

            }

            .text {
                color: #333;
                width: 600px;

            }

            .time {
                color: #999999;
                width: 180px;
                text-align: right;

            }
        }
    }
}

.technology_min {
    .top {
        height: 30px;
        padding-top: 20px;
        margin-bottom: 24px;

        img {
            float: left;
            margin-left: 20px;
            margin-right: 12px;
            display: none;
        }

        h2 {
            float: left;
            margin-left: 20px;
        }

        div {
            float: right;
            color: #9C090F;
            font-size: 16px;
            cursor: pointer;
            line-height: 30px;
            margin-right: 20px;
        }

        div:active {
            opacity: 0.6;
        }
    }

    .list_box {
        .list {
            display: flex;
            width: 300px;
            height: 22px;
            line-height: 22px;
            margin: 16px 20px;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }

            .dian {
                width: 8px;
                height: 8px;
                background: #EF5959;
                border-radius: 50%;
                margin-top: 11px;
                margin-left: 20px;
                margin-right: 12px;
                display: none;
            }

            .text {
                color: #333;
                width: 600px;

            }

            .time {
                color: #999999;
                width: 180px;
                text-align: right;
                display: none;
            }
        }
    }
}
</style>