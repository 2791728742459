 import Router from '@/router'
 //swiperOption：swiper配置项信息，需要绑定在swiper标签的 :option 属性中
export const swiperOption = { 
    //分页器配置项
    pagination: {
      el: ".swiper-pagination", //分页器的类名
      clickable: true, // 点击分页器跳切换到相应的幻灯片
      type: 'bullets' , //小圆点|进度条|数字页码
      dynamicBullets: true,  //动态小圆点(type:'bullets'时)
      //自定义分页器，需设置样式
      renderBullet(index, className) {
        console.log("自定义",className)
          return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
        },
    },   
    //幻灯片播放配置项
    loop: true, //是否循环播放
    speed: 1000, // 发生页面切换动画时，动画的切换速度
    autoplay: {
      delay: 5000, // 幻灯片停留时间
      disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
      stopOnLastSlide: true, // 切换到最后一个slide时是否停止自动切换。（loop模式下无效）。
    },
    on: {
      slideChangeTransitionEnd: function () {
        // console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
      },
      click:function(event){
        let item = event.target.dataset
        console.log(item,item.href)
        if(item.link_type == '1'){
          Router.push(item.href)
        }else if(item.link_type == '2'){
          window.open(item.href)
        }
      }
    },
}