import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import home from '../views/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    children:[
      {
        path: '/',
        name: 'index',
        component: index
      },
      {
        path: '/forum',
        name: 'forum',
        component: () => import('../views/forum/forumHome.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/forum/searchHome.vue')
      },
      {
        path: '/postDetailsDanan',
        name: 'postDetailsDanan',
        component: () => import('../views/forum/postDetailsDanan.vue')
      },
      {
        path: '/postDetails',
        name: 'postDetails',
        component: () => import('../views/forum/postDetails.vue')
      },
      {
        path: '/othersHome',
        name: 'othersHome',
        component: () => import('../views/forum/othersHome.vue')
      },
      {
        path: '/technologystore',
        name: 'technologystore',
        component: () => import('../views/technologystore/technologystore.vue')
      },
      {
        path: '/platformAnn',
        name: 'platformAnn',
        component: () => import('../views/platformAnn/platformAnn.vue')
      },
      {
        path: '/personalcenter',
        name: 'personalcenter',
        component: () => import('../views/personalcenter/index.vue'),
        children:[
          {
            path: '/user/Information',
            name: 'user/Information',
            component: () => import('../views/personalcenter/user/Information')
          },
          {
            path: '/user/home',
            name: 'user/home',
            component: () => import('../views/personalcenter/user/home')
          },
          {
            path: '/other/addressAdmin',
            name: 'other/addressAdmin',
            component: () => import('../views/personalcenter/other/addressAdmin')
          },
          {
            path: '/other/addaddress',
            name: 'other/addaddress',
            component: () => import('../views/personalcenter/other/addaddress')
          },
          {
            path: '/other/introducetTemplate',
            name: 'other/introducetTemplate',
            component: () => import('../views/personalcenter/other/introducetTemplate')
          },
          {
            path: '/other/downloadRecording',
            name: 'other/downloadRecording',
            component: () => import('../views/personalcenter/other/downloadRecording')
          },
          {
            path: '/setup/blacklist',
            name: 'setup/blacklist',
            component: () => import('../views/personalcenter/setup/blacklist')
          },
          {
            path: '/setup/accountSafe',
            name: 'setup/accountSafe',
            component: () => import('../views/personalcenter/setup/accountSafe')
          },
          {
            path: '/setup/editPassword',
            name: 'setup/editPassword',
            component: () => import('../views/personalcenter/setup/editPassword')
          },
          {
            path: '/setup/editPhone',
            name: 'setup/editPhone',
            component: () => import('../views/personalcenter/setup/editPhone')
          },
          {
            path: '/setup/helpFeedback',
            name: 'setup/helpFeedback',
            component: () => import('../views/personalcenter/setup/helpFeedback')
          },
          {
            path: '/setup/opinionFeedback',
            name: 'setup/opinionFeedback',
            component: () => import('../views/personalcenter/setup/opinionFeedback')
          },
          {
            path: '/setup/feedbackRecording',
            name: 'setup/feedbackRecording',
            component: () => import('../views/personalcenter/setup/feedbackRecording')
          },
          {
            path: '/setup/feedbackEssay',
            name: 'setup/feedbackEssay',
            component: () => import('../views/personalcenter/setup/feedbackEssay')
          },
          {
            path: '/setup/agreementEssay',
            name: 'setup/agreementEssay',
            component: () => import('../views/personalcenter/setup/agreementEssay')
          },
          {
            path: '/Integral/Integralmall',
            name: 'Integral/Integralmall',
            component: () => import('../views/personalcenter/Integral/Integralmall')
          },
          {
            path: '/Integral/IntegralDetails',
            name: 'Integral/IntegralDetails',
            component: () => import('../views/personalcenter/Integral/IntegralDetails')
          },
          {
            path: '/Integral/commodityDetails',
            name: 'Integral/commodityDetails',
            component: () => import('../views/personalcenter/Integral/commodityDetails')
          },
          {
            path: '/Integral/confirmOrders',
            name: 'Integral/confirmOrders',
            component: () => import('../views/personalcenter/Integral/confirmOrders')
          },
          {
            path: '/Integral/exchangeSucceed',
            name: 'Integral/exchangeSucceed',
            component: () => import('../views/personalcenter/Integral/exchangeSucceed')
          },
          {
            path: '/Integral/exchangeRecording',
            name: 'Integral/exchangeRecording',
            component: () => import('../views/personalcenter/Integral/exchangeRecording')
          },
          {
            path: '/Integral/ordersDetail',
            name: 'Integral/ordersDetail',
            component: () => import('../views/personalcenter/Integral/ordersDetail')
          },
        ]
      },
      {
        path: '/messageCentre',
        name: 'messageCentre',
        component: () => import('../views/messageCentre/messageCentre.vue'),
        children:[
          {
            path: '/messageCentre/supportBookmark',
            name: '/messageCentre/supportBookmark',
            component: () => import('../views/messageCentre/supportBookmark')
          },
          {
            path: '/messageCentre/addConcern',
            name: '/messageCentre/addConcern',
            component: () => import('../views/messageCentre/addConcern')
          },
          {
            path: '/messageCentre/commentsMy',
            name: '/messageCentre/commentsMy',
            component: () => import('../views/messageCentre/commentsMy')
          },
          {
            path: '/messageCentre/ordersMessage',
            name: '/messageCentre/ordersMessage',
            component: () => import('../views/messageCentre/ordersMessage')
          },
          {
            path: '/messageCentre/platformAnnouncement',
            name: '/messageCentre/platformAnnouncement',
            component: () => import('../views/messageCentre/platformAnnouncement')
          },
        ]
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/bind-mobile',
    name: 'bind-mobile',
    component: () => import('../views/login/bindMobile.vue')
  },
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component: () => import('../views/login/wxLogin.vue')
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/enterprise/authentication.vue')
  },
  {
    path: '/enterpriseAudit',
    name: 'enterpriseAudit',
    component: () => import('../views/enterprise/audit.vue')
  },
  
]

//解决跳转当前页面报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err=>err)
}

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  next();
});

export default router
