<template>
    <div class="notice">
        <div class="top">
            <img src="../../assets/image/gonggao.png" alt="">
            <h2>平台公告</h2>
            <div @click="JumpAnn()">
                更多
                <span class="icon iconfont icon-xiangyou1"></span>
            </div>
        </div>
        <div class="list" v-for="(item, index) of list" :key="index" @click="TabAnn(item)">
            <span>{{ item.title }}</span>
            <p>{{ item.description }}</p>
        </div>
        <div class="empty" v-if="list.length == 0 && !loading">
            <img src="../../assets/image/empty.png" alt="">
        </div>
        <div class="icon_loading" v-show="loading">
            <i class="icon iocnfont el-icon-loading"></i>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: "",
            loading: false
        }
    },
    computed: {
        userinfo() {
            return this.$store.state.userinfo
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.loading = true
            let res = await this.$api.post("6468313980dc7", { user_id: this.userinfo ? this.userinfo.id : "" })
            if (res.code == 1) {
                this.list = []
                this.loading = false
                if(res.data.data.length>3){
                    this.list.push(res.data.data[0])
                    this.list.push(res.data.data[1])
                    this.list.push(res.data.data[2])
                }else{
                    this.list = res.data.data
                }
                
                
            }else{
                this.loading = false
            }
        },
        TabAnn(item) {
            this.$store.commit("changeAnn", item)
            this.$router.push("/platformAnn")
        },
        JumpAnn() {
            this.$router.push("/messageCentre/platformAnnouncement")
        }
    }
}
</script>

<style lang="scss" scoped>
.top {
    height: 30px;
    padding-top: 20px;
    margin-bottom: 24px;

    img {
        float: left;
        margin-left: 20px;
        margin-right: 12px;
    }

    h2 {
        float: left;
    }

    div {
        float: right;
        color: #9C090F;
        font-size: 16px;
        cursor: pointer;
        line-height: 30px;
        margin-right: 20px;
    }

    div:active {
        opacity: 0.6;
    }
}

.list {
    padding: 0 20px;
    margin-top: 28px;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }

    span {
        width: 64px;
        height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;

    }

    p {
        margin-top: 8px;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
    }
}

.empty {
    img {
        width: 340px;
    }
}</style>