import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './static/iconfont/iconfont.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

import Empty from "@/components/empty.vue"
Vue.component('Empty',Empty)
import popupModule from "@/components/popupModule.vue"
Vue.component('popupModule',popupModule)

import popupPermissions from "@/components/popupPermissions.vue"
Vue.component('popupPermissions',popupPermissions)

import http from './http/index.js'
Vue.prototype.$api = http
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// 按需引入
import { Button,Upload,Message,Avatar,Radio,DatePicker,Input,Dialog,Cascader,Switch,Loading } from 'element-ui';
Vue.use(Button);
Vue.use(Upload);
Vue.use(Avatar);
Vue.use(Radio);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(Switch);

Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
