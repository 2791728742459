<template>
    <div class="forum">
        <div class="top" v-if="type == 'home'">
            <img src="../../assets/image/luntan.png" alt="">
            <h2>论坛</h2>
            <div @click="JumpForum()">
                更多
                <span class="icon iconfont icon-xiangyou1"></span>
            </div>
        </div>

        <div class="tab_box">
            <div class="tab" :class="Index == 4 ? 'xuanzhong' : ''" @click="changeTab(4)" v-if="type == 'search'" style="width: 60px;">
                <span>技术库</span>
                <div class="xian" v-show="Index == 4"></div>
            </div>
            <div class="tab" :class="Index == 0 ? 'xuanzhong' : ''" @click="changeTab(0)">
                <span>帖子</span>
                <div class="xian" v-show="Index == 0"></div>
            </div>
            <div class="tab" :class="Index == 1 ? 'xuanzhong' : ''" @click="changeTab(1)">
                <span>问答</span>
                <div class="xian" v-show="Index == 1"></div>
            </div>
            <div class="tab" :class="Index == 2 ? 'xuanzhong' : ''" @click="changeTab(2)" v-if="type == 'forum'">
                <span>关注</span>
                <div class="xian" v-show="Index == 2"></div>
            </div>
            <div class="tab" :class="Index == 3 ? 'xuanzhong' : ''" @click="changeTab(3)" v-if="type == 'user'">
                <span>收藏</span>
                <div class="xian" v-show="Index == 3"></div>
            </div>
        </div>

        <div class="newpost" v-if="type != 'home' && Index == 0 && type != 'user'&& type != 'search'">
            <div :class="newpostIndex == 'show_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'show_num'">
                <img src="../../assets/image/redu.png" style="width:20px;height:20px;vertical-align: -10%;" alt="">
                热度
            </div>
            <div :class="newpostIndex == 'create_time' ? 'newpost_xz' : ''" @click="newpostIndex = 'create_time'">
                最新
            </div>
        </div>
        <div class="newpost" v-if="type != 'home' && Index == 1 && type != 'user'&& type != 'search'">
            <div :class="newpostIndex == 'show_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'show_num'">
                <img src="../../assets/image/redu.png" style="width:20px;height:20px;vertical-align: -10%;" alt="">
                热度
            </div>
            <div :class="newpostIndex == 'create_time' ? 'newpost_xz' : ''" @click="newpostIndex = 'create_time'">
                最新
            </div>
            <div :class="newpostIndex == 'answer_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'answer_num'">
                待回答
            </div>
        </div>
        <div class="newpost" v-if="type == 'user' && Index == 1">
            <div :class="newpostIndex == 'show_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'show_num'">
                问题
            </div>
            <div :class="newpostIndex == 'create_time' ? 'newpost_xz' : ''" @click="newpostIndex = 'create_time'">
                回答
            </div>
        </div>
        <div class="newpost" v-if="type != 'home' && Index == 2">
            <div :class="newpostIndex == 'show_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'show_num'">
                帖子
            </div>
            <div :class="newpostIndex == 'create_time' ? 'newpost_xz' : ''" @click="newpostIndex = 'create_time'">
                问答
            </div>
        </div>
        <div class="newpost" v-if="type == 'user' && Index == 3">
            <div :class="newpostIndex == 'show_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'show_num'">
                帖子
            </div>
            <div :class="newpostIndex == 'create_time' ? 'newpost_xz' : ''" @click="newpostIndex = 'create_time'">
                问题
            </div>
            <div :class="newpostIndex == 'answer_num' ? 'newpost_xz' : ''" @click="newpostIndex = 'answer_num'">
                技术库
            </div>
        </div>

        <div class="list_box scrollShow" :style="MaxHeight ? MaxHeight : ''" ref="dialogCountent" @scroll="scrollGet($event)">
            <!-- 技术库 -->
            <technologylist class="technologylistSty" v-if="type == 'user' && Index == 3 && newpostIndex == 'answer_num'" :dataList="dataList">
            </technologylist>
            <technologylist class="technologylistSty" v-else-if="type == 'search'&&Index==4" :dataList="dataList">
            </technologylist>
            <div v-else>
                <div class="list" v-for="(item, index) of dataList" :key="index" @click="JumpMethod(item)">
                    <!-- 问答 -->
                    <!-- <div class="wendabox omit" v-if="Index == 1&&!newpostIndex">
                <img src="../../assets/iconImg/wenhao.png" alt="">
                <span>123123</span>
            </div> -->
                    <!-- 答案专用 -->
                    <div class="wendabox omit" v-if="type == 'user' && (Index == 1 && newpostIndex == 'create_time')">
                        <img src="../../assets/iconImg/wenhao.png" alt="">
                        <span>{{ item.title || item.freewendaliebiao_title }}</span>
                    </div>
                    <div class="wendabox omit"
                        v-if="type != 'home' && Index == 1 && type != 'user' && newpostIndex == 'answer_num'">
                        <img src="../../assets/iconImg/wenhao.png" alt="">
                        <span>{{ item.title || item.freewendaliebiao_title }}</span>
                    </div>
                    <!-- 帖子 -->
                    <div class="listSty" v-if="Index == 0">
                        <img class="list_img" v-if="item.resources.length > 0" :src="item.resources[0]" alt="">
                        <div class="content">
                            <div class="user">
                                <img :src="item.user_head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.create_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.comment_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_dianzan.png" alt="">
                                <span>{{ item.click_num || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="listSty" v-if="type != 'home' && Index == 2 && newpostIndex == 'create_time'">
                        <img class="list_img" v-if="item.images != null && item.video && item.images != '' && item.video != ''"
                            :src="item.images[0]" alt="">
                        <div class="content">
                            <div class="user">
                                <img :src="item.head_img || item.user_head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.update_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title || item.freewendaliebiao_title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.answer_num || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="listSty" v-if="Index == 2 && newpostIndex == 'show_num'">
                        <img class="list_img" v-if="item.images != null && item.video && item.images != '' && item.video != ''"
                            :src="item.images[0]" alt="">
                        <div class="content">
                            <div class="user">
                                <img :src="item.head_img || item.user_head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.update_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title || item.freewendaliebiao_title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.answer_num || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="listSty" v-if="Index == 3 && newpostIndex == 'show_num'">
                        <img class="list_img" v-if="item.resources.length > 0" :src="item.resources[0]" alt="">
                        <div class="content">
                            <div class="user">
                                <img :src="item.user_head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.create_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.answer_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_dianzan.png" alt="">
                                <span>{{ item.click_num }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- 问答 -->

                    <div class="listSty" v-if="Index == 1">
                        <img class="list_img" v-if="item.images != null && item.video && item.images != '' && item.video != ''"
                            :src="item.images[0]" alt="">
                        <div class="content">
                            <div class="user">
                                <img :src="item.head_img || item.user_head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.update_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title || item.freewendaliebiao_title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.answer_num || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="listSty" v-if="Index == 3 && newpostIndex == 'create_time'">
                        <!-- <img class="list_img" v-if="item.images.length>0" :src="item.images[0]" alt=""> -->
                        <div class="content">
                            <div class="user">
                                <img :src="item.head_img" alt="">
                                <div>
                                    <span>{{ item.user_user_nickname }}</span>
                                    <p>{{ item.update_time }}</p>
                                </div>
                            </div>
                            <!-- 问答没有title   帖子有 -->
                            <div class="title">{{ item.title || item.freewendaliebiao_title }}</div>
                            <div class="text  html" v-html="item.content"></div>
                            <div class="icon_box">
                                <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                                <span>{{ item.show_num || 0 }}</span>
                                <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                                <span>{{ item.answer_num || 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 答案 -->
                    <!-- <div class="listSty"  v-if="Index == 3 && newpostIndex=='answer_num'">
                  <img class="list_img" v-if="item.images!=null&&item.video&&item.images != '' && item.video != ''" :src="item.images[0]" alt="">
                  <div class="content">
                      <div class="user">
                          <img :src="item.user_head_img" alt="">
                          <div>
                              <span>{{ item.user_user_nickname }}</span>
                              <p>{{ item.update_time }}</p>
                          </div>
                      </div>
                      <div class="title">{{ item.freewendaliebiao_title }}</div>
                      <div class="text  html" v-html="item.content"></div>
                      <div class="icon_box">
                          <img src="../../assets/iconImg/lt_yanjing.png" alt="">
                          <span>{{ item.show_num||0 }}</span>
                          <img src="../../assets/iconImg/lt_xiaoxi.png" alt="">
                          <span>{{ item.answer_num||0 }}</span>
                          <img src="../../assets/iconImg/lt_dianzan.png" alt="">
                          <span>{{ item.click_num||0 }}</span>
                      </div>
                  </div>
              </div> -->
                </div>
            </div>


            <empty v-if="dataList.length == 0 && !loading"></empty>
            <div class="icon_loading" v-show="loading">
                <i class="icon iocnfont el-icon-loading"></i>
            </div>
        </div>
    </div>
</template>

<script>
import technologylist from "../technologystore/technologylist.vue"
export default {
    // type:home首页 user个人中心
    props: ["type", "MaxHeight", "id", "user_id", "sort_field"],
    components: {
        technologylist
    },
    data() {
        return {
            Index: this.type == 'search'?4:0,
            text: `<p>推荐各位使用咱们得系统，使用流畅，体验很好推荐各位使用<img src="https://ts1.cn.mm.bing.net/th/id/R-C.df4462fabf18edd07195679a5f8a37e5?rik=FnNvr9jWWjHCVQ&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f50059%2f8720.jpg_wh1200.jpg&ehk=ofb4q76uCls2S07aIlc8%2bab3H5zwrmj%2bhqiZ%2fyw3Ghw%3d&risl=&pid=ImgRaw&r=0" alt="">咱们得系统推荐各位使用咱们得系统，使用流畅，体验很好推荐各位使用推荐各位使用咱们得系统，使用流畅，体验很好推荐各位使用咱们得系统推荐各位使用咱们得系统，使用流畅，体验很好推荐各位使用</p>`,
            newpostIndex: 'show_num',
            page: 1,
            dataList: [],
            isLoading: true,
            Iurl: "",
            loading: false
        }
    },
    computed: {
        keywords() {
            return this.$store.state.keywords
        },
        userinfo() {
            return this.$store.state.userinfo
        }
    },
    watch: {
        newpostIndex() {
            console.log("进了没")
            this.page = 1
            // if(this.type == 'user' && this.newpostIndex == 'create_time' && this.Index != 3){
            //     this.ReplayInterlocutionList()
            // }else 
            if (this.Index == 3 && this.newpostIndex == 'show_num') {
                this.Iurl = "652a2d3498af4"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 3 && this.newpostIndex == 'create_time') {
                this.Iurl = "652a2f3201347"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 3 && this.newpostIndex == 'answer_num') {
                this.Iurl = "6540600115bc9"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 2 && this.newpostIndex == 'show_num') {
                this.Iurl = "6384630b71f81"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 2 && this.newpostIndex == 'create_time') {
                this.Iurl = "651f767acf033"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 1 && this.newpostIndex == 'show_num') {
                this.Iurl = "651f767acf033"
                this.dataList = []
                this.InterlocutionList()
            } else if (this.Index == 1 && this.newpostIndex == 'create_time') {
                if(this.type == "user"){
                    this.dataList = []
                    this.ReplayInterlocutionList()
                }else{
                    this.Iurl = "651f767acf033"
                    this.dataList = []
                    this.InterlocutionList()
                }
                
            } else if (this.Index == 1 && this.newpostIndex == 'answer_num') {
                this.Iurl = "651f767acf033"
                this.dataList = []
                this.InterlocutionList()
            } else {
                this.InterlocutionList()
            }
        },
        keywords(){
            this.InterlocutionList()
        }
    },
    mounted() {
        this.Iurl = this.Index==4?"650960f1bb935":"6384630b71f81"
        if (this.sort_field) {
            this.newpostIndex = this.sort_field
        }
        this.InterlocutionList()

    },
    methods: {
        changeTab(val) {
            this.Index = val
            this.page = 1
            this.dataList = []
            if (this.type == 'user' && val == 0) {
                this.newpostIndex = this.sort_field
            } else if (this.type == 'home') {
                this.newpostIndex = this.sort_field
            } else {
                this.newpostIndex = 'show_num'
            }
            if (val == 0) {
                this.Iurl = "6384630b71f81"
            } else if (val == 1) {

                this.Iurl = "651f767acf033"

            } else if (val == 2) {
                this.Iurl = "6384630b71f81"
            } else if (val == 3) {
                this.Iurl = "652a2d3498af4"
            }else if (val == 4) {
                this.Iurl = "650960f1bb935"
            }
            this.InterlocutionList()
        },
        JumpForum() {
            this.$store.commit("changeIndex", 2)
            this.$router.push("/forum")
        },
        JumpMethod(item) {
            if (this.userinfo == null) {
                this.$message.error("您还没有登录，请先登录")
                this.$router.push("/login")
                return
            }
            if (this.Index == 0) {
                this.postInformation(item)
            } else if (this.Index == 1) {
                if (this.type == 'user') {

                    if (this.newpostIndex == 'show_num') {
                        this.ReplayInformation(item)
                    } else if (this.newpostIndex == "create_time") {
                        this.JumpDetails(item)
                    }
                } else {
                    this.ReplayInformation(item)
                }

            } else if (this.Index == 3  ) {
                if (this.newpostIndex == 'show_num') {
                        this.postInformation(item)
                    } else if (this.newpostIndex == "create_time") {
                        this.ReplayInformation(item)
                    }else if(this.newpostIndex == 'answer_num'){
                        this.JumpDetails(item)
                    }
                
            } else if(this.Index == 2){
                if (this.newpostIndex == 'show_num') {
                        this.postInformation(item)
                    } else  {
                        this.ReplayInformation(item)
                    }
                
            }else{
                this.ReplayInformation(item)
            }

        },
        async JumpDetails(item) {
            let res = await this.$api.post("652262dc3f018", { aid: item.aid })
            if (res.code == 1) {
                this.$router.push({
                    path: "/postDetailsDanan",
                    query: {
                        t: 3,
                        aid: item.aid
                    }
                })
                this.$store.commit("changeReplayUser", res.data)
            }
        },
        //发帖信息
        async postInformation(item) {
            let res = await this.$api.post("6384686524dfb", { aid: item.aid })
            if (res.code == 1) {
                this.$router.push({
                    path: "/postDetails",
                    query: {
                        t: 4,
                        aid: item.aid
                    }
                })
                this.$store.commit("changeReplayUser", res.data)
            }
        },
        //问答详情
        async ReplayInformation(item) {
            let res = await this.$api.post("6520ecf8e2964", { aid: item.aid })
            if (res.code == 1) {
                this.$router.push({
                    path: "/postDetails",
                    query: {
                        t: 2,
                        aid: item.aid
                    }
                })
                this.$store.commit("changeReplayUser", res.data)
            }
        },
        //问答列表
        async InterlocutionList() {
            this.dataList = []
            this.loading = true
            let type = ""
            let data = {
                page: this.page,
                sort_field: this.newpostIndex,
                user_id: this.user_id,
                type: type,
                keywords: this.keywords,
            }
            if (this.type == "user" || this.type == "others") {
                data.type = 1
            } else if (this.Index == 2) {
                data.type = 2
            } else if (this.type == "home" && this.userinfo) {
                data.user_id = this.userinfo.id
            }
            let res = await this.$api.post(this.Iurl, data)
            console.log(res)
            if (res.code == 1) {
                if (this.$refs.dialogCountent.scrollTop != 0) {
                    this.$refs.dialogCountent.scrollTop = 0
                }
                this.loading = false
                this.dataList = res.data.data
                console.log(this.dataList)
            } else {
                this.loading = false
            }
        },
        async InterlocutionListtScroll() {
            let data = {
                page: this.page,
                sort_field: this.newpostIndex,
                user_id: this.user_id,
                type: this.Index == 2 ? 2 : "",
                keywords: this.keywords
            }
            let res = await this.$api.post(this.Iurl, data)
            console.log(res)
            if (res.data.data.length > 0) {
                this.dataList.push(...res.data.data);//追加数据 使用 ...语法
                this.isLoading = true;
            } else {
                console.log("没有更多信息")
                this.isLoading = false;//无数据可以加载
            }
        },
        //滚动分页加载数据
        scrollGet(e) {
            let scrollHeight = this.$refs.dialogCountent.scrollHeight;
            let clientHeight = this.$refs.dialogCountent.clientHeight;
            let scrollTop = this.$refs.dialogCountent.scrollTop;
            if (scrollHeight - (scrollTop + clientHeight) <= 1 && this.isLoading) {
                this.page = this.page + 1;
                if (this.type == 'user' && this.newpostIndex == 'create_time') {

                    this.ReplayInterlocutionListScroll()
                } else {
                    this.InterlocutionListtScroll()
                }
            }

        },
        //答案列表
        async ReplayInterlocutionList() {
            let data = {
                page: this.page,
                freewendaliebiao_id: "",
                sort_field: "create_time",
                user_id: this.user_id
            }
            let res = await this.$api.post("651f7ec3ae232", data)
            console.log(res)
            if (res.code == 1) {
                this.dataList = res.data.data
                console.log(this.dataList)
            }
        },
        async ReplayInterlocutionListScroll() {
            let data = {
                page: this.page,
                freewendaliebiao_id: "",
                sort_field: "create_time",
                user_id: this.user_id
            }
            let res = await this.$api.post("651f7ec3ae232", data)
            console.log(res)
            if (res.data.data.length > 0) {
                this.dataList.push(...res.data.data);//追加数据 使用 ...语法
                this.isLoading = true;
            } else {
                console.log("没有更多信息")
                this.isLoading = false;//无数据可以加载
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.forum {
    height: 100%;

    .top {
        height: 30px;
        padding-top: 20px;
        margin-bottom: 24px;

        img {
            float: left;
            margin-left: 20px;
            margin-right: 12px;
        }

        h2 {
            float: left;
        }

        div {
            float: right;
            color: #9C090F;
            font-size: 16px;
            cursor: pointer;
            line-height: 30px;
            margin-right: 20px;
        }

        div:active {
            opacity: 0.6;
        }
    }
}

.tab_box {
    height: 40px;
    width: 100%;

    .tab {
        width: 50px;
        text-align: center;
        height: 40px;
        position: relative;
        float: left;
        cursor: pointer;
        margin: 0 16px;

        span {
            color: #333333;
            font-size: 16px;
        }

        .xian {
            position: absolute;
            bottom: -2px;
            height: 4px;
            width: 30px;
            left: calc(50% - 15px);
            border-radius: 2px;
            background: #9C090F;
        }

    }

    .xuanzhong {
        span {
            font-size: 20px;
            color: #9C090F;
            font-weight: bold;
        }
    }
}

.newpost {
    border-top: 1px solid #eee;
    overflow: hidden;
    height: 68px;
    width: calc(100% - 40px);
    margin-left: 20px;

    div {
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 32px;
        color: #333;
        font-size: 16px;
        padding: 0 22px;
        float: left;
        cursor: pointer;
        margin-top: 18px;
        border: 1px solid #fff;
        box-sizing: border-box;
    }

    .newpost_xz {
        background: rgba(252, 84, 85, 0.1);
        border: 1px solid #9C090F;

        color: #9C090F;

    }
}

.list_box {
    padding: 0 20px;
    overflow-y: scroll;
    height: 100%;

    .list:last-child {
        border-bottom: 1px solid #eee;
    }

    .list {
        border-top: 1px solid #eee;

        .wendabox {
            width: 100%;
            background: rgba(239, 89, 89, 0.04);
            height: 48px;
            margin-top: 16px;
            color: #333;
            line-height: 48px;

            img {
                width: 24px;
                height: 24px;
                vertical-align: -12%;
                margin-left: 16px;
                margin-right: 4px;
            }
        }

        .listSty {

            display: flex;

            .list_img {
                width: 165px;
                height: 148px;
                margin: 12px 16px 12px 0;
                border-radius: 8px;
            }

            .content {
                width: calc(100% - 204px);

                .user {
                    margin-top: 12px;
                    overflow: hidden;

                    img {
                        float: left;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                    }

                    div {
                        float: left;
                        margin-left: 16px;

                        span {
                            color: #333;
                            font-size: 16px;
                            font-weight: bold;
                        }

                        p {
                            font-size: 14px;
                            margin-top: 4px;
                            color: #999;
                        }
                    }
                }

                .title {
                    font-size: 14px;
                    color: #333;
                    font-weight: bold;
                    margin: 8px 0;
                    height: 19px;
                    overflow: hidden;
                    text-overflow:ellipsis; 
                    white-space: nowrap;
                }

                .text {
                    font-size: 14px;
                    color: #666;
                    max-height: 38px;
                    overflow: hidden;

                    p {
                        img {
                            display: none !important;
                        }
                    }

                }

                .icon_box {
                    overflow: hidden;
                    height: 16px;
                    line-height: 16px;
                    margin-top: 16px;
                    padding-bottom: 12px;

                    img {
                        width: 16px;
                        height: 16px;
                        vertical-align: -10%;
                    }

                    span {
                        font-size: 14px;
                        color: #999;
                        margin-right: 120px;
                        margin-left: 6px;
                    }
                }

            }
        }
    }
}
.technologylistSty{
    border-top:1px solid #eee;
}</style>