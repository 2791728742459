<template>
  <div class="login">
    <div class="center">
        <span class="left">HI！欢迎来到赛玛特传动技术（北京）有限公司技术论坛</span>
        <div class="right">
            <span>{{ employ.kf_tel }}</span>
            <img src="../assets/iconImg/dianhua.png" class="dianhua" alt="">
            <div class="xian"></div>
            <img @click="JumpMessage()" src="../assets/iconImg/xiaoxi.png" alt="">
            <div class="tishi" v-if="jbFalse&&jb.all*1>0">{{ jb.all }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            jb:{},
            jbFalse:false
        }
    },
    computed:{
        employ() {
            return this.$store.state.employ
        },
        userinfo(){
            return this.$store.state.userinfo
        }
    },
    watch:{
        $route(){
            if(this.userinfo){
                this.jiaobiao()
            }
        }
    },
    mounted(){
        if(this.userinfo){
            this.jiaobiao()
        }
        
    },
    methods:{
        JumpMessage(){
            this.$router.push("/messageCentre/supportBookmark")
        },
        async jiaobiao(){
            
            let res = await this.$api.post("62d1071033336",{list_rows:15})
            if(res.code == 1){
                this.jbFalse = true
                this.jb = res.data
                this.$store.commit("changeJiaobiao",res.data)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login{
    height:52px;
    background: #FFFFFF;
}
.center{
    width:1200px;
    height:52px;
    margin:0 auto;
    font-size: 16px;
    line-height: 52px;
    color:#999999;
    .left{
        float: left;
    }
    .right{
        float: right;
        position: relative;
        img{
            float: right;
            cursor: pointer;
            margin-top:17px;
            width:20px;
            height:20px;
        }
        .xian{
            float: right;
            width:1px;
            height:18px;
            background:#eee;
            margin:18px 16px;

        }
        span{
            float: right;
            font-size:14px;
            color:#999;
        }
        .dianhua{
            margin-right:8px;
        }
        .tishi{
            position: absolute;
            top:12px;
            left:12px;
            width:12px;
            height:12px;
            border-radius: 50%;
            background:red;
            color:#fff;
            font-size:10px;
            line-height: normal;
            text-align: center;
            line-height: 12px;
        }
    }
}
</style>