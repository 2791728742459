import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bytes:"Copyright© zkh.com 1998 - 2023 Corporation All Rights Reserved 沪ICP备10006078号-12 赛玛特技术论坛",
    employ:localStorage.getItem("employ")?JSON.parse(localStorage.getItem("employ")):{},
    Index:0,
    userinfo:localStorage.getItem("userinfo")?JSON.parse(localStorage.getItem("userinfo")):null,  //用户信息
    ann:localStorage.getItem("ann")?JSON.parse(localStorage.getItem("ann")):{}, //平台公告数据
    ReplayUser:localStorage.getItem("ReplayUser")?JSON.parse(localStorage.getItem("ReplayUser")):{},
    Address:{},
    ReportData:[],
    morenAddress:{},
    commodityIn:{},
    keywords:'',
    jiaobiao:{}
  },
  getters: {
  },
  mutations: {
    changeIndex(state,data){
      state.Index = data
    },
    userInfo(state,data){
      console.log("用户",data)
      state.userinfo = data
      if(data == null) {
        localStorage.removeItem('userinfo')
        localStorage.removeItem('user_token')
      }else{
        localStorage.setItem('userinfo',JSON.stringify(data))
        if(data.user_token){
          localStorage.setItem('user_token',data.user_token)
        }
      }
    },
    changeAnn(state,data){
      state.ann = data
      localStorage.setItem('ann',JSON.stringify(data))
    },
    changeReplayUser(state,data){
      state.ReplayUser = data
      localStorage.setItem('ReplayUser',JSON.stringify(data))
    },
    changeAddress(state,data){
      state.Address = data
    },
    changeReport(state,data){
      state.ReportData = data
    },
    MorenAddress(state,data){
      state.morenAddress = data
    },
    changeCommodityIn(state,data){
      state.commodityIn = data
    },
    changeEmploy(state,data){
      console.log("工作日 我进来了",data)
      state.employ = data
      localStorage.setItem('employ',JSON.stringify(data))
    },
    changeKeywords(state,data){
      state.keywords = data
    },
    changeJiaobiao(state,data){
      state.jiaobiao = data
    }
  },
  actions: {
  },
  modules: {
  }
})
