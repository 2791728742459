<!-- tinymc富文本 -->
<template>
  <div>
    <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick">
    </editor>
  </div>
</template>
  
<script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/skins/ui/oxide/skin.css";
import "@/assets/tinymce/zh_CN.js"; // 本地汉化
import "tinymce/themes/silver/theme";
import "tinymce/plugins/image";
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
// import 'tinymce/plugins/imagetools';
import "tinymce/icons/default"; // 图标 -解决测试环境找不icon问题
import serverConfig from "../http/config.js"
import axios from "axios";
export default {
  name: "tinymce",
  components: {
    Editor,
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        // ' anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools insertdatetime link lists   noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
        "lists image media table  wordcount ",
    },
    toolbar: {
      type: [String, Array],
      default:
        //'undo redo | searchreplace | bold  italic | underline | strikethrough | alignleft  aligncenter alignright | outdent indent  blockquote  removeformat subscript superscript code codesample hr bullist numlist link image charmap preview anchor pagebreak insertdatetime  table  forecolor backcolor'
        "undo redo |  formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists  table | removeformat hr image",
    },
  },
  data() {
    return {
      //初始化配置
      init: {
        language_url: "@/asset/tinymce/langs/zh_CN.js", // 这里需要单独处理
        language: "zh_CN",
        height: 254,
        width: 696,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false, //顶部菜单栏
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          console.log("图片",blobInfo)
          // const img = "data:image/jpeg;base64," + blobInfo.base64();
          this.upImg(blobInfo,success,failure)
          // success(img);
        },
        resize: true,
      },
      myValue: this.value,
    };
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    clear() {
      this.myValue = "";
    },
    upImg(blobInfo,success,failure) {
      let formdata = new FormData()
      // append 方法中的第一个参数就是 我们要上传文件 在后台接收的文件名
      // 这个值要根据后台来定义
      // 第二个参数是我们上传的文件
      formdata.append('file', blobInfo.blob())
      // axios 定义上传方法
      axios({
        method: "post", // post方法
        url: serverConfig.baseURL + '62ddf0e4eadde', // 请求上传图片服务器的路径
        headers: {
        // 配置headers请求头
          // Authorization: localStorage.getItem('logintoken'), // token认证，看后台
          'Content-Type': 'application/x-www-form-urlencoded', // 采用表单上传的方式，看后台如何接受
        },
        data: formdata // 请求数据formdata
      }).then(res => {
        console.log("res",res)
        if (res.data.code != 1) {
          // 上传失败执行此方法，将失败信息填入参数中
          failure('HTTP Error: ' + res.msg);
          return
        }
        // 上传成功之后，将对应完整的图片路径拼接在success的参数中
        success(res.data.data[0].path);
      })
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style scoped></style>
  