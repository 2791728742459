<template>
    <div class="indexBottom">
        <div class="content">
            <div class="left">
                <div class="ljjump">
                    <div class="text" @click="Jump('帮助中心')">帮助中心</div>
                    <div class="xian"></div>
                    <div class="text" @click="Jump('关于我们')">关于我们</div>
                    <div class="xian"></div>
                    <div class="text" @click="Jump('隐私政策')">隐私政策</div>
                    <div class="xian"></div>
                    <div class="text" @click="Jump('注册协议')">注册协议</div>
                </div>
                <div class="employ">
                    <div class="text">免费客服电话 {{ employ.kf_tel }}</div>
                    <div class="xian"></div>
                    <div class="text">工作日： {{ employ.kf_gongzuori }}</div>
                    <div class="xian"></div>
                    <div class="text">休息日： {{ employ.kf_xiuxiri }}</div>
                </div>
            </div>

            <div class="right">
                <img src="https://www.shsaimate.cn/static/img/images/wx_xcx.jpg" alt="">
                <span>用户小程序端</span>
            </div>
        </div>
        <div class="bottom">
            {{ bytes }}
        </div>
        <popupModule v-if="popupShow" :title="title" @clone="popupShow = false">
            <template slot="content">
                <div class="xieyiSty" v-html="xieyi.content"></div>
            </template>
        </popupModule>
    </div>
</template>

<script>
export default {
    computed: {
        bytes() {
            return this.$store.state.bytes
        },
        employ() {
            return this.$store.state.employ
        }
    },
    data(){
        return{
            title:"",
            popupShow:false
        }
    },
    mounted(){
        this.work()
    },
    methods: {
        Jump(val) {
            if (val == "帮助中心") {
                this.$router.push({
                    path:"/setup/helpFeedback",
                    query:{
                        name:'帮助与反馈'
                    }
                })
            } else if (val == "关于我们") {
                this.$router.push({
                    path:"/setup/agreementEssay?id=5",
                    query:{
                        name:"关于我们"
                    }
                })
            } else if (val == "隐私政策") {
                this.changePopup("隐私政策",2)
            } else if (val == "注册协议") {
                this.changePopup("注册协议",1)
            }
        },
        changePopup(title, id) {
            this.title = title

            this.changeXieyi(id)
        },
        // 系统协议
        async changeXieyi(id) {
            let res = await this.$api.post("62c393b950315", { aid: id })
            if (res.code == 1) {
                this.xieyi = res.data
                this.popupShow = true
            }
        },
        //工作日
        async work(){
            let res = await this.$api.post("652dea0c58137")
           
            if(res.code == 1){
                this.$store.commit("changeEmploy",res.data)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.indexBottom {
    width: 100%;
    background: #333;

    .content {
        height: 180px;
        width: 1200px;
        margin: 0 auto;
    }

    .bottom {
        height: 44px;
        background: rgba(255, 255, 255, 0.06);
        line-height: 44px;
        color: #999;
        font-size: 12px;
        text-align: center;
    }
}

.left {
    float: left;

    .ljjump {
        display: flex;
        margin-top: 50px;

        .xian {
            background: rgba(255, 255, 255, 0.8);
            height: 16px;
            width: 1px;
            margin: 4px 28px 0 28px;
        }

        .text {
            color: #fff;
            cursor: pointer;
        }

        .text:hover {
            opacity: 0.6;
        }
    }

    .employ {
        display: flex;
        margin-top: 30px;

        .xian {
            background: #999;
            height: 12px;
            width: 1px;
            margin: 4px 20px 0 20px;
        }

        .text {
            font-size: 12px;
            color: #999;
        }
    }
}

.right {
    float: right;
    height: 180px;
    line-height: 180px;
    color: #fff;

    img {
        width: 100px;
        height: 100px;
        vertical-align: -24%;
        margin-right: 16px;
    }
}
.xieyiSty{
  padding:20px;
  max-height:600px;
  overflow-y: scroll;
}</style>