const api = {
    // exploit:"http://amt.jishu11.com",
    exploit:"https://www.shsaimate.cn",
    // exploit:"/apis",
    disposition:"/api/m4204/"
}


const serverConfig = {
    baseURL: api.exploit + api.disposition, // 请求基础地址,可根据环境自定义
    useTokenAuthorization: true, // 是否开启 token 认证
  };
export default serverConfig;